import { template as template_051a91cdc1784d42b7d13138a39cf935 } from "@ember/template-compiler";
const FKControlMenuContainer = template_051a91cdc1784d42b7d13138a39cf935(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
