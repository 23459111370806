import { template as template_83c2194258364f22a87fef5e9846778f } from "@ember/template-compiler";
const FKLabel = template_83c2194258364f22a87fef5e9846778f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
