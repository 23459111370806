import { template as template_092c06e3b8374f958008d4e3d3608845 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_092c06e3b8374f958008d4e3d3608845(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
