import { template as template_2ed92687788a443eb229b3ea3ed3da4a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_2ed92687788a443eb229b3ea3ed3da4a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
